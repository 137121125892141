import { jsx as r, jsxs as p, Fragment as R } from "react/jsx-runtime";
import { useConfig as m } from "@intergamma/config";
import { dispatchAdobeEvent as z } from "@intergamma/adobe-tracking";
import { useTranslation as g } from "react-i18next";
import { useMemo as D, useState as P } from "react";
import { cn as b } from "@intergamma/common/cn";
import { X as L } from "react-feather";
import { connectAutoComplete as T, connectStateResults as B, InstantSearch as O, Configure as j } from "react-instantsearch-dom";
import { Downshift as K, DownshiftMenu as M, DownshiftItems as U, DownshiftItem as $ } from "@intergamma/downshift";
import J from "algoliasearch";
import Q from "js-cookie";
const F = () => {
  if (typeof localStorage > "u")
    return;
  const e = localStorage.getItem("queryList");
  if (e)
    try {
      return JSON.parse(e);
    } catch {
      return;
    }
};
function H(e, t) {
  const { t: n } = g("ig-search-bar");
  if (e.length !== 0)
    return t;
  const o = F(), s = [];
  if (o && o.length > 0)
    for (let a = 0; a < o.length; a += 1) {
      const l = {
        query: o[a]
      };
      a === 0 && (l.typeLabel = n("RecentQueries")), s.push(l);
    }
  return s;
}
const x = ({ ...e }) => /* @__PURE__ */ r(
  "input",
  {
    ...e,
    className: b(
      "box-border w-full appearance-none border font-normal transition-all duration-200",
      "[&::-webkit-search-cancel-button]:appearance-none",
      "md:absolute md:right-0 md:transition-[width] md:duration-100",
      "focus:outline-none",
      "karwei:h-[40px] karwei:rounded-[3px] karwei:pl-[10px] karwei:pr-[40px]",
      "gamma:h-[46px] gamma:rounded-full gamma:pl-[17px] gamma:pr-[44px]",
      "border-ignew-neutral-400 bg-white text-black karwei:text-ignew-neutral-900",
      "gamma:focus:rounded-full karwei:focus:rounded-[3px]",
      "focus:border-brand-primary  focus:shadow-[0_0_0_3px] focus:shadow-brand-primary/20 karwei:focus:border-ignew-neutral-900",
      "md:text-[1em]",
      "lg:focus:w-[600px]",
      "peer"
    )
  }
);
function X({ query: e, item: t }) {
  const { t: n } = g("ig-search-bar");
  if (!t.query)
    return null;
  const o = t.query.substr(0, e.length);
  return /* @__PURE__ */ p("div", { className: "leading-[28px]", children: [
    String(o).valueOf() === String(e).valueOf() ? /* @__PURE__ */ r("strong", { children: e }) : o,
    t.query.substr(e.length),
    " ",
    t.category && /* @__PURE__ */ p("span", { className: "query-category lowercase text-brand-primary", children: [
      n("In"),
      " ",
      t.category
    ] }),
    t.typeLabel && /* @__PURE__ */ r("span", { className: "suggestion-type left-0 right-auto top-[-12px] text-[13px] first-letter:capitalize md:left-auto md:right-4 md:top-[-17px] ", children: t.typeLabel })
  ] });
}
function E({
  refine: e,
  hits: t,
  searchResults: n,
  value: o,
  setValue: s,
  onSelect: a,
  downshiftEnvironment: l
}) {
  const { isMobile: h } = m(), { t: u } = g("ig-search-bar"), c = (n == null ? void 0 : n.query) ?? "", y = H(c, t);
  return /* @__PURE__ */ r(
    K,
    {
      environment: l,
      selectedItem: { query: o },
      itemToString: (i) => typeof i == "object" ? (i == null ? void 0 : i.query) || "" : i || "",
      onSelect: (i) => {
        i && i.query && a(i);
      },
      onInputValueChange: (i) => {
        s(i);
      },
      children: ({
        getInputProps: i,
        getItemProps: v,
        getMenuProps: C,
        highlightedIndex: k,
        isOpen: N,
        openMenu: q
      }) => {
        const _ = i({
          onFocus() {
            q();
          },
          onChange(d) {
            e(d.currentTarget.value);
          },
          autoComplete: "off",
          autoCapitalize: "off",
          autoCorrect: "off"
        });
        return /* @__PURE__ */ p(R, { children: [
          /* @__PURE__ */ r(
            x,
            {
              type: "search",
              name: "text",
              placeholder: u(h ? "Search" : "SearchByKeyword"),
              required: !0,
              ..._
            }
          ),
          /* @__PURE__ */ r(
            M,
            {
              ...C(),
              isOpen: N && y.length > 0,
              role: "form",
              "aria-label": u("Suggestions"),
              children: /* @__PURE__ */ r(U, { role: "listbox", "aria-label": u("Suggestions"), children: y.map((d, f) => {
                const A = v({
                  item: d,
                  index: f
                });
                return d.showIndex = f + 1, /* @__PURE__ */ r(
                  $,
                  {
                    highlighted: f === k,
                    ...A,
                    role: "option",
                    children: /* @__PURE__ */ r(X, { query: c, item: d })
                  },
                  f
                );
              }) })
            }
          )
        ] });
      }
    }
  );
}
const G = T(
  B(E)
), S = ({
  children: e,
  ...t
}) => /* @__PURE__ */ r("form", { ...t, className: "relative h-[46px] w-full karwei:h-[40px]", children: e });
function I() {
  const { t: e } = g("ig-search-bar");
  return /* @__PURE__ */ p(W, { type: "submit", children: [
    /* @__PURE__ */ r("span", { className: "sr-only", children: e("Search") }),
    /* @__PURE__ */ r(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        focusable: "false",
        className: "absolute left-[11px] top-[11px] z-[1]",
        children: /* @__PURE__ */ r("path", { d: w, fill: "#fff", fillRule: "evenodd", clipRule: "evenodd" })
      }
    ),
    /* @__PURE__ */ r(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        className: "absolute left-[11px] top-3 z-0 opacity-[0.3]",
        focusable: "false",
        children: /* @__PURE__ */ r(
          "path",
          {
            d: w,
            fill: "#000",
            fillRule: "evenodd",
            clipRule: "evenodd",
            className: "shadow"
          }
        )
      }
    )
  ] });
}
const W = ({
  children: e,
  ...t
}) => /* @__PURE__ */ r(
  "button",
  {
    ...t,
    className: b(
      "absolute z-[500] size-[40px] cursor-pointer border-none text-white transition-all duration-200",
      "bg-brand-primary karwei:bg-ignew-neutral-900",
      "karwei:right-0 karwei:top-0",
      "gamma:right-[3px] gamma:top-[3px]",
      "karwei:rounded-r-[3px]",
      "gamma:rounded-full",
      "focus:bg-ignew-functional-focus-900 focus:outline-none focus:karwei:bg-ignew-neutral-200",
      "focus:shadow-sm"
    ),
    children: e
  }
), w = "M244.2 214.6l-54.4-54.4-1-.7a103 103 0 1 0-29.4 29.4l.8 1 54.4 54.3a21 21 0 0 0 29.6-29.6zm-141.3-44.5a67.2 67.2 0 1 1 0-134.4 67.2 67.2 0 0 1 0 134.4z";
function Y() {
  const e = m();
  return D(() => {
    const t = J(
      e.algoliaApplicationId,
      e.suggestionApiKey
    );
    return {
      ...t,
      search(o) {
        return o.every(({ params: s }) => !s.query) ? Promise.resolve({
          results: o.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0
          }))
        }) : t.search(o);
      }
    };
  }, [e.algoliaApplicationId, e.suggestionApiKey]);
}
function Z(e, t, n, o) {
  e != null && e.objectID && window.fetch("https://insights.algolia.io/1/events", {
    method: "POST",
    headers: {
      "X-Algolia-Application-Id": t,
      "X-Algolia-API-Key": n,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      events: [
        {
          eventType: "click",
          eventName: "Search Suggestion Clicked",
          index: o,
          userToken: Q.get("search_user_token"),
          timestamp: Date.now(),
          objectIDs: [e.objectID],
          queryID: e.queryID,
          positions: [e.showIndex]
        }
      ]
    })
  });
}
function V(e) {
  if (!e || e === "")
    return;
  const t = "queryList", n = localStorage.getItem(t);
  let o;
  try {
    n && (o = JSON.parse(n)), o || (o = []), o.includes(e) || (o.unshift(e), o.length > 4 && o.pop()), localStorage.setItem(t, JSON.stringify(o));
  } catch {
  }
}
function ee() {
  if (typeof window > "u")
    return "";
  const e = /[?&]text=([^&#]*)/.exec(window.location.search);
  return e ? decodeURIComponent(e[1].replace(/\+/g, " ")) : "";
}
function te(e) {
  if (typeof e == "string")
    return `?text=${encodeURIComponent(e)}`;
  let t = `?text=${encodeURIComponent(e.query)}`;
  return e.category && (t += `&f_categories=${encodeURIComponent(e.category)}`), t;
}
function oe({
  onSubmit: e,
  algoliaSuggestionIndex: t,
  downshiftEnvironment: n
}) {
  const { t: o } = g("ig-search-bar"), [s, a] = P(ee);
  function l() {
    a("");
  }
  const h = Y();
  function u(c) {
    typeof c != "string" && V(c.query), e(c);
  }
  return /* @__PURE__ */ p(
    S,
    {
      method: "get",
      action: o("/search"),
      onSubmit: (c) => {
        c.preventDefault(), u(s);
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ p(
          O,
          {
            searchClient: h,
            indexName: t,
            children: [
              /* @__PURE__ */ r(j, { clickAnalytics: !0, hitsPerPage: 4 }),
              /* @__PURE__ */ r(
                G,
                {
                  value: s,
                  onSelect: (c) => u(c),
                  setValue: a,
                  downshiftEnvironment: n
                }
              )
            ]
          }
        ),
        s && /* @__PURE__ */ r(
          re,
          {
            onClick: () => l(),
            title: o("ClearResults"),
            "aria-label": o("ClearResults"),
            type: "reset",
            children: /* @__PURE__ */ r(ne, { "aria-hidden": "true", focusable: "false" })
          }
        ),
        /* @__PURE__ */ r(I, {})
      ]
    }
  );
}
const re = ({
  children: e,
  ...t
}) => /* @__PURE__ */ r(
  "button",
  {
    ...t,
    className: b(
      "absolute right-[45px] top-1/2 z-[500] size-6 -translate-y-1/2 cursor-pointer rounded-full text-center",
      "grid place-items-center border-0 p-0 transition-shadow",
      "hover:shadow-[0_3px_7px_rgba(0,0,0,0.25)]",
      "focus:rounded-full focus:shadow-[0_0_0_3px_var(--tw-shadow-color)]"
    ),
    children: e
  }
), ne = (e) => /* @__PURE__ */ r(
  L,
  {
    className: "static mt-0.5 size-[20px] stroke-[3px] text-ignew-neutral-500",
    ...e
  }
);
function me({
  downshiftEnvironment: e
}) {
  const t = m(), { t: n } = g("ig-search-bar"), o = t.algoliaSuggestionIndex ? t.algoliaSuggestionIndex : n("algoliaSuggestionIndex");
  function s(a) {
    typeof a != "string" && (z({
      type: "click_search_suggestions",
      data: {
        user_selected_value: a.query,
        position: a.showIndex
      }
    }), Z(
      a,
      t.algoliaApplicationId,
      t.suggestionApiKey,
      o
    ));
    const l = `${n("/search")}${te(a)}`;
    window.location.href = l;
  }
  return /* @__PURE__ */ r(
    oe,
    {
      onSubmit: (a) => s(a),
      algoliaSuggestionIndex: o,
      downshiftEnvironment: e
    }
  );
}
function be() {
  const { t: e } = g("ig-search-bar");
  return /* @__PURE__ */ p(
    S,
    {
      method: "get",
      action: e("/search"),
      onSubmit: (t) => {
        t.preventDefault();
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ r(
          x,
          {
            type: "search",
            name: "text",
            autoComplete: "off",
            autoCapitalize: "off",
            autoCorrect: "off",
            required: !0
          }
        ),
        /* @__PURE__ */ r(I, {})
      ]
    }
  );
}
export {
  be as LoadingSearchBar,
  me as MainSearchBar,
  oe as SearchBar,
  V as addQueryResultToLocalStorage,
  ee as getQuerySearchInitialValue,
  te as getSearchUrl,
  Z as sendResultToAlgolia
};
