import o from "axios";
import { fetchAuthSession as T } from "aws-amplify/auth";
import h from "js-cookie";
const p = "IGAppAuth";
async function w() {
  var n;
  const r = h.get(p);
  if (r)
    return r;
  try {
    const { tokens: e } = await T();
    return ((n = e == null ? void 0 : e.idToken) == null ? void 0 : n.toString()) ?? null;
  } catch {
    return null;
  }
}
const u = async (r) => {
  const n = await w();
  return {
    ...n && { Authorization: `Bearer ${n}` },
    ...r
  };
};
function l(r, n) {
  const e = r;
  return e.cancel = n, e;
}
const { CancelToken: d } = o;
function S(r, n) {
  return {
    async get(e, t) {
      const a = await u(t == null ? void 0 : t.headers), c = d.source(), { data: s } = await l(
        o.get(e, {
          ...n,
          ...t,
          baseURL: r,
          cancelToken: c.token,
          headers: a
        }),
        c.cancel
      );
      return s;
    },
    async post(e, t, a) {
      const c = await u(a == null ? void 0 : a.headers), s = d.source(), { data: k } = await l(
        o.post(e, t, {
          ...n,
          ...a,
          baseURL: r,
          cancelToken: s.token,
          headers: c
        }),
        s.cancel
      );
      return k;
    },
    async put(e, t, a) {
      const c = await u(a == null ? void 0 : a.headers), s = d.source(), { data: k } = await l(
        o.put(e, t, {
          ...n,
          ...a,
          baseURL: r,
          cancelToken: s.token,
          headers: c
        }),
        s.cancel
      );
      return k;
    },
    async delete(e, t) {
      const a = await u(t == null ? void 0 : t.headers), c = d.source(), { data: s } = await l(
        o.delete(e, {
          ...n,
          ...t,
          baseURL: r,
          cancelToken: c.token,
          headers: a
        }),
        c.cancel
      );
      return s;
    }
  };
}
export {
  p as I,
  u as a,
  S as c,
  w as g,
  l as w
};
