function a() {
  return !!(process.env.VITE_FORCE_KIOSK && process.env.VITE_FORCE_KIOSK === "true" || process.env.NEXT_PUBLIC_FORCE_KIOSK && process.env.NEXT_PUBLIC_FORCE_KIOSK === "true");
}
function I(e, i, s) {
  var t;
  if (!("chrome" in window) || !((t = chrome == null ? void 0 : chrome.runtime) != null && t.sendMessage))
    throw new Error("Chrome is not defined");
  try {
    return new Promise(
      (n, o) => (
        // eslint-disable-next-line no-promise-executor-return
        chrome.runtime.sendMessage(
          e,
          { key: i, payload: s },
          (r) => r ? "data" in r ? n(r.data) : "error" in r ? o(r.error) : o() : o()
        )
      )
    );
  } catch (n) {
    throw new Error(n);
  }
}
function u() {
  return process.env.VITE_KIOSK_EXTENSION_ID ? process.env.VITE_KIOSK_EXTENSION_ID : process.env.NEXT_PUBLIC_KIOSK_EXTENSION_ID ? process.env.NEXT_PUBLIC_KIOSK_EXTENSION_ID : document.location.host.includes("acceptatie") ? "bfblbleokjnmiigkafjmpgkmdeojfhka" : "hmgofimfefbcoomlplagdbofhglfkkpl";
}
async function c() {
  var e;
  if (a() || typeof window < "u" && ((e = window == null ? void 0 : window.navigator) != null && e.userAgent.includes("IG-Kiosk")))
    return !0;
  try {
    return await I(
      u(),
      "VALIDATE_IS_KIOSK"
    );
  } catch {
    return !1;
  }
}
export {
  c as a,
  u as g,
  a as i,
  I as s
};
