import n from "mailcheck";
function m(o, l) {
  o || l(void 0), n.run({
    email: o,
    domains: e,
    secondLevelDomains: [],
    topLevelDomains: [],
    suggested: l,
    empty: () => l(void 0)
  });
}
const e = [
  "a2000.nl",
  "belgacom.net",
  "caiway.nl",
  "casema.nl",
  "chello.nl",
  "facebook.com",
  "freeler.nl",
  "gmail.com",
  "gmx.com",
  "google.com",
  "googlemail.com",
  "hetnet.nl",
  "home.nl",
  "hotmail.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "icloud.com",
  "kpnmail.nl",
  "kpnplanet.nl",
  "live.be",
  "live.com",
  "live.fr",
  "live.nl",
  "me.com",
  "msn.com",
  "onsbrabantnet.nl",
  "onsmail.nl",
  "outlook.com",
  "outlook.be",
  "outlook.fr",
  "pandora.be",
  "planet.nl",
  "proximus.be",
  "quicknet.nl",
  "scarlet.be",
  "skynet.be",
  "telenet.be",
  "telfort.nl",
  "telfortglasvezel.nl",
  "tvcablenet.be",
  "upcmail.nl",
  "voo.be",
  "wp.pl",
  "wxs.nl",
  "xmsnet.nl",
  "xmsweb.nl",
  "xs4all.nl",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.fr",
  "ziggo.nl",
  "zeelandnet.nl",
  "zonnet.nl",
  "kabelfoon.nl",
  "online.nl",
  "aol.com",
  "hotmail.be",
  "hotmail.nl",
  "lycos.nl",
  "mail.be",
  "ilse.nl",
  "12move.nl",
  "alice.nl",
  "hccnet.nl",
  "orange.nl",
  "scarlet.nl",
  "tele2.nl",
  "tiscali.nl",
  "wanadoo.nl"
];
export {
  m as checkEmail,
  m as default
};
