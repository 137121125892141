function r(a, t) {
  if (a.length < 2)
    throw new Error(`Could not get allocation for ${a}`);
  const n = 1 / a.length * t * 100, o = 1 / a.length * (t + 1) * 100;
  return [n, o];
}
function e(a) {
  const t = {};
  if (!a.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [n, o] of a.entries())
    t[o] = {
      allocation: r(a, n)
    };
  return t;
}
const _ = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_1971-disabled-dba_4": {
    scope: "local",
    description: "New CTA buttons",
    variants: {
      "A-control": { allocation: [0, 100] },
      "B-new": { allocation: [100, 100] }
    },
    domain_and_index: "abtest_name_dba_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_2978-dba_8": {
    scope: "local",
    description: "New cart summary design",
    variants: e(["A-control", "B-succinct", "C-verbose"]),
    domain_and_index: "abtest_name_dba_8",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3080-dba_5": {
    scope: "local",
    description: "Meest gebruikt betaalmethode",
    variants: e(["A-control", "B-text"]),
    domain_and_index: "abtest_name_dba_5",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3090-dba_3": {
    scope: "local",
    description: "Legal text betalen button",
    variants: e(["A-control", "B-below"]),
    domain_and_index: "abtest_name_dba_3",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_button_shapes-ew_8": {
    scope: "local",
    description: "Cross domain test for new button shapes",
    variants: e(["A-control", "B-variant"]),
    domain_and_index: "abtest_name_ew_8",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  _ as experiments
};
