import { ComponentPropsWithRef, ReactNode } from "react";

import { cn } from "@intergamma/common/cn";

type DescriptionProps = ComponentPropsWithRef<"span"> & {
  children: ReactNode;
};

export function Description({ className, ...props }: DescriptionProps) {
  return (
    <span
      className={cn(
        "text-75/5 gamma:text-neutral-700 karwei:text-brand-primary",
        className,
      )}
      {...props}
    />
  );
}
