import { jsx as t } from "react/jsx-runtime";
import { Close as r } from "@intergamma/icons";
import { cssTransition as n, ToastContainer as e } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const s = n({
  enter: "intergamma-toast-fwd",
  exit: "intergamma-toast-bck"
});
function l() {
  return /* @__PURE__ */ t(
    e,
    {
      hideProgressBar: !0,
      theme: "colored",
      transition: s,
      closeButton: ({ closeToast: o }) => (
        // eslint-disable-next-line tailwindcss/no-custom-classname
        /* @__PURE__ */ t("button", { className: "closeButton", onClick: o, children: /* @__PURE__ */ t(r, {}) })
      )
    }
  );
}
export {
  l as IntergammaToastContainer
};
