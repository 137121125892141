import { ComponentPropsWithRef, ReactNode } from "react";

import { cn } from "@intergamma/common/cn";

type ErrorProps = ComponentPropsWithRef<"span"> & {
  children: ReactNode;
};

export function Error({ className, ...props }: ErrorProps) {
  return (
    <span
      className={cn(
        "text-75/5 gamma:text-ignew-functional-quaternary-700 karwei:text-ignew-functional-quaternary-600",
        className,
      )}
      role="alert"
      {...props}
    />
  );
}
