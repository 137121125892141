import { jsx as o } from "react/jsx-runtime";
import { createContext as t, useContext as a } from "react";
import { karwei_nl as n, gamma_be as s, gamma_nl as u } from "./nextjs.js";
const r = t(null), l = () => {
  const e = a(r);
  if (!e)
    throw new Error("useLegacyTheme must be used within a LegacyThemeProvider");
  switch (e) {
    case "gamma_nl":
      return u;
    case "gamma_be":
      return s;
    case "karwei_nl":
      return n;
    default:
      throw new Error(`LegacyThemeProvider: invalid formula: ${e}`);
  }
}, w = () => {
  const e = a(r);
  if (!e)
    throw new Error("useFormula must be used within a LegacyThemeProvider");
  return e;
}, g = ({
  children: e,
  formula: m
}) => /* @__PURE__ */ o(r.Provider, { value: m, children: e });
export {
  g as LegacyThemeProvider,
  w as useFormula,
  l as useLegacyTheme
};
