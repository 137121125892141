import { cn } from "@intergamma/common";

import * as RadixPopover from "@radix-ui/react-popover";

type TooltipProps = {
  title?: string;
  content: string;
  children: React.ReactNode;
  onChange?: (open: boolean) => void;
  side?: RadixPopover.PopoverContentProps["side"];
};

export function Tooltip({
  title,
  content,
  children,
  onChange,
  side = "top",
}: TooltipProps) {
  return (
    <RadixPopover.Root onOpenChange={onChange}>
      <RadixPopover.Trigger
        type="button"
        className={cn(
          "pointer-events-auto rounded-full",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
        )}
      >
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          className="z-[1000] flex w-full max-w-xs flex-col gap-2 rounded-lg bg-brand-primary p-4 text-white focus:outline-none"
          side={side}
          sideOffset={2}
          collisionPadding={8}
        >
          {title && <span className="text-100/6 font-bold">{title}</span>}
          <span className="text-100/6">{content}</span>
          <RadixPopover.Arrow
            className="fill-brand-primary"
            width={24}
            height={12}
          />
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
