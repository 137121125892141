/* eslint-disable tailwindcss/no-custom-classname */
import { ComponentPropsWithRef, forwardRef } from "react";

import { cn } from "@intergamma/common/cn";

import { Info } from "../icons";

type FormNotificationProps = ComponentPropsWithRef<"div"> & {
  variant: "success" | "warning" | "error";
  title: string;
  message?: string;
};

// TODO figure out a (unique) name for this component. "Notification" is already used for an icon :|
export const FormNotification = forwardRef<
  HTMLDivElement,
  FormNotificationProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function FormNotification(
    { variant, title, message, className, ...props },
    ref,
  ) {
    return (
      <div
        className={cn(
          variant === "error" && "border-functional-quaternary-800",
          variant === "warning" && "border-functional-tertiary-500",
          variant === "success" && "border-functional-secondary-800",
          "flex gap-2 rounded-md border border-l-[3px] p-4",
          className,
        )}
        ref={ref}
        {...props}
      >
        {variant === "error" && (
          <Info className="text-functional-quaternary-800" />
        )}
        {variant === "warning" && (
          <Info className="text-functional-tertiary-500" />
        )}
        {variant === "success" && (
          <Info className="text-functional-secondary-800" />
        )}
        <div className="flex-1">
          <div className="font-bold">{title}</div>
          {message && <div>{message}</div>}
        </div>
      </div>
    );
  },
);
