import { jsx as t } from "react/jsx-runtime";
import { forwardRef as c } from "react";
import { cn as r } from "@intergamma/common/cn";
const f = r(
  "cursor-pointer rounded-[3px] underline outline-none gamma:text-brand-primary karwei:text-[#111316]",
  "ring-offset-1 focus-visible:ring-2 focus-visible:ring-ignew-functional-focus-400",
  "hover:no-underline"
), l = c(
  // eslint-disable-next-line prefer-arrow-callback
  function({ children: n, className: o, ...e }, i) {
    return /* @__PURE__ */ t("a", { className: r(f, o), ...e, ref: i, children: n });
  }
);
export {
  l as Anchor,
  f as anchorStyle
};
