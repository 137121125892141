import { ComponentProps, forwardRef } from "react";

export const ArrowLeft = forwardRef<SVGSVGElement, ComponentProps<"svg">>(
  // eslint-disable-next-line prefer-arrow-callback
  function ArrowLeft(props, ref) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M12.2599 5.65081C12.6194 5.23148 12.5708 4.60018 12.1515 4.24076C11.7321 3.88134 11.1008 3.9299 10.7414 4.34923L4.18359 12L10.7414 19.6508C11.1008 20.0701 11.7321 20.1187 12.1515 19.7593C12.5708 19.3999 12.6194 18.7686 12.2599 18.3492L7.6749 13H19.0007C19.553 13 20.0007 12.5523 20.0007 12C20.0007 11.4477 19.553 11 19.0007 11H7.67488L12.2599 5.65081Z"
          fill="currentColor"
        />
      </svg>
    );
  },
);
