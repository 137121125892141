import { jsxs as p, Fragment as te, jsx as e } from "react/jsx-runtime";
import { createContext as He, useContext as je, useMemo as se, useCallback as W, useState as S, useEffect as I, useId as Ke, useRef as ce } from "react";
import { useTranslation as R, Trans as Ee } from "react-i18next";
import { useConfig as j } from "@intergamma/config";
import { PrivacyMask as J } from "@intergamma/privacy-mask";
import { dispatchAdobeEvent as E } from "@intergamma/adobe-tracking";
import { calculatePasswordStrength as Oe, useStrengthText as Ue, TextField as de, PasswordField as Re, CheckboxField as _e, VerifyCodeField as Fe } from "@intergamma/ui/form";
import { Button as X } from "@intergamma/ui/button";
import { Edit as pe, Correct as Ie } from "@intergamma/icons";
import { ExclamationMark as ke, CheckMark as ze } from "@intergamma/ui/icons";
import { Snackbar as ue } from "@intergamma/ui/snackbar";
import { cn as $ } from "@intergamma/common/cn";
import { useSearchParams as Qe, useLocation as Je, Navigate as Xe, useNavigate as Ze } from "react-router-dom";
import { useForm as he, Controller as fe } from "react-hook-form";
import { zodResolver as we } from "@hookform/resolvers/zod";
import { datadogRum as re } from "@datadog/browser-rum";
import { isAxiosError as ve } from "axios";
import { useMutation as me, useQuery as et } from "@tanstack/react-query";
import { c as tt, a as rt } from "./index-C2EW4nef.js";
import { l as ge } from "./datadog-logging-CPRR9qp_.js";
import { z as U } from "zod";
import { isKiosk as st } from "@intergamma/kiosk-util";
import nt from "@intergamma/mailcheck";
import { toast as be } from "react-toastify";
import { signIn as ot } from "aws-amplify/auth";
import { LoyaltyCard as it } from "@intergamma/loyalty-card";
import { getMobileOperatingSystem as at, MobileOperatingSystem as lt } from "@intergamma/common";
var k = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.SIGN_UP = 1] = "SIGN_UP", r[r.VERIFY = 2] = "VERIFY", r[r.FORGOT_PASSWORD = 3] = "FORGOT_PASSWORD", r))(k || {}), ee = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.RESEND_VERIFICATION = 1] = "RESEND_VERIFICATION", r[r.RESEND_SUCCESS = 2] = "RESEND_SUCCESS", r))(ee || {}), Te = /* @__PURE__ */ ((r) => (r.CODE = "CODE", r.HASH = "HASH", r))(Te || {});
const ct = {
  signUpRequestBody: {
    email: "",
    password: "",
    locale: "nl",
    optIn: !1,
    verificationMethod: "CODE",
    passwordless: !1
  },
  setSignUpRequestBody: () => {
  },
  setSignUpResponse: () => {
  },
  loginAfterSignUp: !1,
  setLoginAfterSignUp: () => {
  },
  setVerificationError: () => {
  },
  setFlow: () => {
  }
}, qe = He(ct), dt = qe.Provider, ae = () => je(qe);
function le(r) {
  const s = tt(`${r}/api`, {
    withCredentials: !0
  });
  return {
    getAccountState(t) {
      return s.get(
        `/account/?email=${encodeURIComponent(t)}`
      );
    },
    resendVerification(t) {
      return s.post(
        "/signup/resendsignupverification",
        t
      );
    },
    signUp(t) {
      return s.post("/signup", t);
    },
    verify(t, a, i) {
      return s.post(`/verify/${t}?hash=${a}`, {
        hash: a,
        customerId: i
      });
    },
    passwordReset(t) {
      return s.post("/password-reset", t);
    },
    verifyPasswordReset(t) {
      return s.post("/verify/password-reset", t);
    }
  };
}
const Le = (r) => {
  switch (r) {
    case "nl_BE":
    case "nl-BE":
      return "nl_BE";
    case "fr_BE":
    case "fr-BE":
      return "fr_BE";
    default:
      return "nl";
  }
};
class F extends Error {
  constructor(s) {
    super(s), this.name = "TranslatedError";
  }
}
function ut({ onSuccess: r }) {
  const { MYACCOUNT_URL: s } = j(), { t, i18n: a } = R(["register", "errors"]), i = se(
    () => le(s),
    [s]
  ), c = W(() => {
    re.addAction("signup");
  }, []);
  function o({ password: n, email: u, optIn: d, passwordless: l }) {
    return i.signUp({
      email: u,
      password: n,
      locale: Le(a.language),
      optIn: d,
      verificationMethod: "CODE",
      passwordless: l
    }).catch((f) => {
      var g, w, b;
      if (ve(f) && ((w = (g = f.response) == null ? void 0 : g.data) != null && w.status)) {
        const L = ((b = f.response) == null ? void 0 : b.data).status;
        if (L === "UserSignUpInvalidEmail")
          throw new F(t("register:invalidEmail"));
        if (L === "UserSignUpPasswordInvalid")
          throw new F(t("register:invalidSignupPassword"));
        if (L === "UserSignUpAlreadyExists")
          throw new F(t("register:userAlreadyExists"));
      }
      throw ge.error("Create user submitForm failed", {
        errorDetails: f == null ? void 0 : f.message
      }), new F(
        `${t("errors:somethingWentWrong")} ${t(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me(o, {
    useErrorBoundary: !1,
    onSuccess: (n, u) => {
      c(), E({
        type: "sign_up_interaction",
        data: {
          user_selected_element: "Sign_up verification",
          user_message_value: `Commercial opt-in: ${u.optIn}`,
          user_selected_value: `Verification method: ${u.passwordless ? "passwordless" : "email"}`
        }
      }), r(n, u);
    },
    onError: (n) => {
      E({
        type: "sign_up_error",
        data: {
          error: n == null ? void 0 : n.message
        }
      });
    }
  });
}
const Me = (r, s, t) => ({
  gamma: {
    nl: {
      loyaltyProgram: `${t}/voordeelpas`,
      requirementsLoyalty: `${t}/klantenservice/algemene-voorwaarden/voordeelpas`
    },
    "nl-BE": {
      loyaltyProgram: `${t}/nl/klanteninformatie/gammapluskaart`,
      requirementsLoyalty: `${t}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`
    },
    "fr-BE": {
      loyaltyProgram: `${t}/fr/infos-consommateur/carte-gammaplus`,
      requirementsLoyalty: `${t}/fr/infos-consommateur/carte-gammaplus`
    }
  },
  karwei: {
    nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    },
    karwei_nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    }
  }
})[r][s], mt = "account";
function De() {
  const { t: r } = R(["register", "errors"]), { MYACCOUNT_URL: s } = j(), t = se(
    () => le(s),
    [s]
  ), [a, i] = S(void 0), c = W(async () => a ? t.getAccountState(a).catch((l) => {
    throw E({
      type: "login_email_check_error",
      data: {
        error: (l == null ? void 0 : l.message) || ""
      }
    }), new F(
      `${r("errors:somethingWentWrong")} ${r(
        "errors:ContactCustomerSupport"
      )}`
    );
  }) : null, [a, t, r]), { data: o, isFetching: n, refetch: u, error: d } = et(
    [mt, a],
    c,
    {
      suspense: !1,
      useErrorBoundary: !1
    }
  );
  return {
    accountState: o,
    isFetching: n,
    error: d,
    refetch: u,
    getAccountState: (l) => {
      i(l);
    }
  };
}
function gt({
  isPasswordless: r
}) {
  const { t: s } = R(["register"]);
  return U.object({
    email: U.string().min(1, s("register:emailRequired")).email(s("register:emailInvalid")),
    ...r ? {} : {
      password: U.string().min(8, s("register:minPasswordLength")).max(50, s("register:maxPasswordLength"))
    },
    requirements: U.literal(!0, {
      errorMap: () => ({ message: s("register:requirementsError") })
    }),
    optIn: U.boolean()
  });
}
function ft({ setShowLoyaltyBenefits: r }) {
  var ne, K;
  const { t: s, i18n: t } = R([
    "common",
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [a] = Qe(), i = a.get("method") === "passwordless", { signUpRequestBody: c, setSignUpRequestBody: o, setFlow: n } = ae(), u = gt({ isPasswordless: i }), {
    mutate: d,
    error: l,
    isLoading: f
  } = ut({
    onSuccess: (h, M) => {
      o((D) => ({ ...D, ...M })), n({
        flow: k.VERIFY,
        verificationData: {
          customerId: h.customerId,
          control: h.control,
          email: M.email
        }
      }), r == null || r(!0), i && ge.info("Passwordless sign-up successful");
    }
  }), [g, w] = S(null), { brand: b, SHOPFRONT_URL: N } = j(), { getAccountState: L, accountState: B, isFetching: A } = De(), [y, T] = S(null), P = Me(b, t.language, N), G = (P == null ? void 0 : P.requirementsLoyalty) ?? "/", Y = (P == null ? void 0 : P.loyaltyProgram) ?? "/", {
    email: q,
    password: v,
    optIn: V
  } = c, _ = he({
    defaultValues: {
      email: q,
      password: v,
      requirements: !1,
      optIn: V
    },
    resolver: we(u)
  }), ie = _.watch("password"), Z = se(
    () => Oe(ie),
    [ie]
  ), C = Ue(Z);
  I(() => {
    l != null && l.message && T(l.message);
  }, [l]);
  const H = _.handleSubmit(
    (h) => {
      L(h.email), w({
        ...h,
        locale: "nl",
        // FIXME: get locale from somewhere?
        verificationMethod: "CODE",
        password: i ? window.crypto.randomUUID() : h.password,
        passwordless: i
      });
    },
    (h) => {
      for (const { message: M } of Object.values(h))
        E({
          type: "sign_up_error",
          data: {
            error: M
          }
        });
    }
  );
  return I(() => {
    B && !A && (!B.hasAccount && g ? d({
      email: g.email,
      password: g.password,
      optIn: g.optIn,
      passwordless: g.passwordless
    }) : T(s("register:userAlreadyExists")));
  }, [
    B,
    A,
    s,
    g,
    d,
    o
  ]), I(() => {
    E({
      type: "sign_up_interaction",
      data: {
        user_selected_element: "Sign-up pop-up form"
      }
    });
  }, []), /* @__PURE__ */ p(te, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: s("login-form:buttonLabelNewAccount") }),
    /* @__PURE__ */ p(
      "form",
      {
        noValidate: !0,
        onSubmit: (h) => (h.stopPropagation(), H(h)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
            de,
            {
              type: "email",
              label: s("common:EmailAddress"),
              error: (ne = _.formState.errors.email) == null ? void 0 : ne.message,
              autoCapitalize: "off",
              autoComplete: "email",
              disabled: !0,
              icon: /* @__PURE__ */ e(
                "button",
                {
                  className: $(
                    "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                    "outline-none ring-offset-1 focus-visible:ring-2 focus-visible:ring-ignew-functional-focus-400"
                  ),
                  onClick: () => {
                    E({
                      type: "login_interaction",
                      data: {
                        user_selected_element: "Sign-up pop-up change e-mail link click"
                      }
                    }), n({ flow: k.LOGIN });
                  },
                  type: "button",
                  children: /* @__PURE__ */ e(pe, {})
                }
              ),
              ..._.register("email")
            }
          ) }) }) }),
          !i && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
            Re,
            {
              autoComplete: "new-password",
              autoFocus: !0,
              label: s("login-form:Password"),
              error: (K = _.formState.errors.password) == null ? void 0 : K.message,
              passwordStrength: Z,
              description: C,
              ..._.register("password")
            }
          ) }) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: _.control,
              name: "requirements",
              defaultValue: !1,
              render: ({ field: h, fieldState: M }) => {
                var D;
                return /* @__PURE__ */ e(
                  _e,
                  {
                    ref: h.ref,
                    name: h.name,
                    checked: h.value,
                    "data-testid": "requirements-checkbox",
                    onCheckedChange: (m) => {
                      m !== "indeterminate" && h.onChange(m);
                    },
                    error: (D = M.error) == null ? void 0 : D.message,
                    label: /* @__PURE__ */ p(Ee, { ns: "login", i18nKey: "requirements", children: [
                      "Met het aanmaken van een account, ga ik akkoord met de",
                      /* @__PURE__ */ e(
                        "a",
                        {
                          target: "_blank",
                          href: G,
                          rel: "noreferrer",
                          children: "voorwaarden"
                        }
                      ),
                      "en profiteer ik van alle gratis voordelen van de",
                      /* @__PURE__ */ e(
                        "a",
                        {
                          target: "_blank",
                          href: Y,
                          rel: "noreferrer",
                          children: "pas"
                        }
                      )
                    ] })
                  }
                );
              }
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: _.control,
              name: "optIn",
              defaultValue: !1,
              render: ({ field: h, fieldState: M }) => {
                var D;
                return /* @__PURE__ */ e(
                  _e,
                  {
                    ref: h.ref,
                    name: h.name,
                    checked: h.value,
                    onCheckedChange: (m) => {
                      m !== "indeterminate" && h.onChange(m);
                    },
                    error: (D = M.error) == null ? void 0 : D.message,
                    label: s("register:optinNewsletter")
                  }
                );
              }
            }
          ) }),
          y && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: y }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            X,
            {
              loading: A || f,
              className: "w-full text-center",
              type: "submit",
              children: s("register:description")
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ p("p", { children: [
            /* @__PURE__ */ e("b", { children: s("register:alreadyHaveAccount") }),
            /* @__PURE__ */ e("br", {}),
            /* @__PURE__ */ e(
              "button",
              {
                className: $(
                  "underline gamma:rounded-md",
                  "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                ),
                onClick: () => n({ flow: k.LOGIN }),
                children: s("register:loginUrl")
              }
            )
          ] }) })
        ]
      }
    )
  ] });
}
function $e() {
  const { MYACCOUNT_URL: r } = j(), { i18n: s, t } = R(["register"]), a = se(
    () => le(r),
    [r]
  ), i = W((o) => {
    re.addAction("resend_verification_failed", {
      errorDetails: o == null ? void 0 : o.message
    });
  }, []);
  function c({
    email: o,
    sms: n,
    phoneNumber: u
  }) {
    return a.resendVerification({
      email: o,
      locale: Le(s.language),
      sms: n,
      phoneNumber: u,
      verificationMethod: "CODE"
    }).catch((d) => {
      var l;
      if (E({
        type: "resend_verification_error",
        data: {
          user_message_type: (d == null ? void 0 : d.message) || "",
          verificationMethod: n ? "sms" : "email"
        }
      }), ve(d) && ((l = d.response) == null ? void 0 : l.data).status === "ResendInvalidEmail")
        throw new F(
          t(n ? "register:phoneNumberAlreadyExists" : "register:userAlreadyExists")
        );
    }).finally(() => {
      E({
        type: "resend_verification",
        data: {
          verificationMethod: n ? "sms" : "email"
        }
      });
    });
  }
  return me(c, {
    useErrorBoundary: !1,
    onError: (o) => {
      i(o);
    }
  });
}
function pt({
  email: r,
  goToStep: s,
  goToFlow: t
}) {
  const { t: a } = R("login-form"), { mutate: i, isLoading: c, isSuccess: o, data: n } = $e();
  I(() => {
    o && n && t({
      flow: k.VERIFY,
      verificationData: {
        customerId: n.customerId,
        control: n.control,
        email: n.email
      }
    });
  }, [o, s, t, n]);
  const u = () => {
    E({ type: "sign_up_change_email" }), s(ee.LOGIN);
  };
  return /* @__PURE__ */ p("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ p("div", { children: [
      /* @__PURE__ */ e("p", { children: a("bodyNoVerfication") }),
      /* @__PURE__ */ e("p", { children: a("bodyNoVerification2") }),
      /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e("strong", { children: r }) }) })
    ] }),
    /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      X,
      {
        className: "w-full",
        loading: c,
        onClick: () => i({ email: r }),
        children: a("buttonLabelResendEmail")
      }
    ) }),
    /* @__PURE__ */ e("div", { className: "text-center", children: /* @__PURE__ */ e(
      "button",
      {
        className: $(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: u,
        children: a("buttonLabelOtherEmail")
      }
    ) })
  ] });
}
function ht({ email: r }) {
  const { t: s } = R("login-form");
  return /* @__PURE__ */ p(te, { children: [
    /* @__PURE__ */ e("p", { children: s("bodyConfirmEmailSent") }),
    /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("strong", { children: r }) }) }),
    /* @__PURE__ */ e("p", { children: s("bodyEmailSteps") })
  ] });
}
function wt({ onSubmit: r, isLoading: s }) {
  const { t } = R("login-form"), { brand: a, country: i } = j();
  let c = [];
  a === "gamma" ? c = i === "be" || i === "BE" ? ["2613", "2614", "2617", "2618", "2629"] : ["2611", "2615", "2616"] : a === "karwei" && (c = ["2612", "2619"]);
  const n = U.object({
    loyaltyCardNumber: U.string().min(1, t("cardNumberRequired")).length(13, t("LoyaltyCardNumberInvalid")).refine((l) => c.some((f) => l.startsWith(f)), {
      message: t("LoyaltyCardNumberInvalid")
    }).refine(
      (l) => l.split("").reduce(
        (g, w, b) => b % 2 === 0 ? g + Number(w) : g + 3 * Number(w),
        0
      ) % 10 === 0,
      {
        message: t("LoyaltyCardNumberInvalid")
      }
    )
  }), u = he({
    mode: "onBlur",
    defaultValues: {
      loyaltyCardNumber: ""
    },
    resolver: we(n)
  }), d = u.handleSubmit((l) => {
    r(l.loyaltyCardNumber);
  });
  return /* @__PURE__ */ p(te, { children: [
    /* @__PURE__ */ p("div", { className: "relative flex p-4 items-center", children: [
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" }),
      /* @__PURE__ */ e("span", { className: "flex-shrink mx-4 text-ignew-neutral-500 uppercase", children: t("or") }),
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" })
    ] }),
    /* @__PURE__ */ p(
      "form",
      {
        noValidate: !0,
        onSubmit: (l) => (l.stopPropagation(), d(l)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e(
            fe,
            {
              rules: {
                required: t("cardNumberRequired"),
                pattern: {
                  value: /^\d+$/,
                  message: t("LoyaltyCardNumberInvalid")
                }
              },
              render: ({ field: { onChange: l, ...f } }) => {
                var g;
                return /* @__PURE__ */ e(
                  de,
                  {
                    onChange: (w) => {
                      (w.target.value === "" || /^[0-9\b]+$/.test(w.target.value)) && l(w.target.value);
                    },
                    type: "text",
                    label: t("enterLoyaltyCardNumber"),
                    maxLength: 13,
                    inputMode: "numeric",
                    error: (g = u.formState.errors.loyaltyCardNumber) == null ? void 0 : g.message,
                    ...f
                  }
                );
              },
              control: u.control,
              name: "loyaltyCardNumber",
              defaultValue: ""
            }
          ),
          /* @__PURE__ */ e(X, { type: "submit", variant: "secondary", loading: s, children: t("RegisterLoyaltyCardNumber") })
        ]
      }
    )
  ] });
}
function vt({
  showTermsAndConditions: r,
  showPasswordField: s
}) {
  const { t } = R(["ig-header", "login"]);
  return U.object({
    email: U.string().min(1, t("ig-header:loginEmailRequired")).email(t("ig-header:loginEmailInvalid")),
    password: s ? U.string().min(1, t("ig-header:loginRequired")) : U.string(),
    requirements: r ? U.literal(!0, {
      errorMap: () => ({ message: t("login:requirementsError") })
    }) : U.any()
  });
}
function bt({
  isLoading: r,
  onLogin: s,
  errorMessage: t,
  setShowLoyaltyBenefits: a,
  loyaltyCardLogin: i,
  initLocation: c,
  loginData: o
}) {
  var xe;
  const { t: n, i18n: u } = R([
    "ig-header",
    "login",
    "urls",
    "login-form",
    "register",
    "common"
  ]), d = Je(), [l, f] = S(null), { signUpRequestBody: g, setSignUpRequestBody: w, setFlow: b, loginAfterSignUp: N } = ae(), { brand: L, SHOPFRONT_URL: B } = j(), A = Ke(), [y, T] = S(
    !!(o != null && o.email)
  ), [P, G] = S(void 0), [Y, q] = S(!1), [v, V] = S(ee.LOGIN), _ = Me(L, u.language, B), ie = (_ == null ? void 0 : _.requirementsLoyalty) ?? "/", Z = (_ == null ? void 0 : _.loyaltyProgram) ?? "/", {
    accountState: C,
    getAccountState: H,
    refetch: ne,
    error: K,
    isFetching: h
  } = De(), M = N ? g.email : ((xe = d.state) == null ? void 0 : xe.email) || (o == null ? void 0 : o.email), D = vt({
    showTermsAndConditions: Y,
    showPasswordField: y
  }), m = he({
    defaultValues: {
      email: M || "",
      password: "",
      requirements: !1
    },
    resolver: we(D)
  }), oe = () => {
    P && (m.setValue("email", P.full), G(void 0), E({ type: "email_suggestion_click" }));
  }, ye = m.watch("email"), We = async (x) => {
    const z = await m.trigger("email");
    x && z && (x === g.email && C ? ne() : H(x), w((Q) => ({
      ...Q,
      email: x,
      password: ""
    })), E({ type: "login_email_check" }));
  }, Ae = W(
    (x) => {
      x != null && x.length && (C != null && C.customerId) && b({
        flow: k.VERIFY,
        verificationData: {
          email: x,
          customerId: C.customerId,
          loginViaCode: !0
        }
      });
    },
    [C, b]
  );
  I(() => {
    o && !C && !h && H(o.email);
  }, [o, C, h, H]), I(() => {
    var x;
    if (C && !h) {
      const { hasAccount: z, hasLoyaltyCard: Q, verified: O, hasPassword: Ce } = C;
      (x = document.getElementById(`password_${A}`)) == null || x.focus(), z ? O ? /* If in kiosk mode, start the login by code flow by default */ /* When showPasswordField is true it is redirected from login with code */ l && !y || // If a user has no password but wants to reset it, we should show the password field.
      !Ce && !(o != null && o.forcePasswordFlow) ? Ae(g.email) : (T(!0), m.setFocus("password")) : V(ee.RESEND_VERIFICATION) : b({ flow: k.SIGN_UP }), z && !Q && (q(!0), typeof a < "u" && a(!0));
    }
  }, [
    C,
    h,
    a,
    A,
    b,
    g.email,
    l
  ]), I(() => {
    (async () => {
      f(await st());
    })();
  }, []), I(() => {
    N && typeof a < "u" && a(!0);
  }, [N, a]), I(() => {
    v === ee.LOGIN && E({
      type: "login_interaction",
      data: {
        user_selected_element: y ? "Login pop-up" : "Login / Signup email check pop-up",
        ...!y && { user_selected_value: c }
      }
    });
  }, [v, y, c]);
  const Ye = m.handleSubmit((x) => {
    const { email: z, password: Q } = x;
    C != null && C.customerId && Q && s({
      body: {
        customerId: C.customerId,
        email: z,
        password: Q
      }
    });
  });
  return /* @__PURE__ */ e("div", { "data-testid": "login-form", children: ((x) => {
    var z, Q;
    switch (x) {
      case ee.LOGIN:
        return /* @__PURE__ */ p(te, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n(y ? "login-form:loginTitle" : "login-form:titleLogInOrSignUp") }),
          /* @__PURE__ */ p(
            "form",
            {
              noValidate: !0,
              onSubmit: (O) => {
                O.stopPropagation(), Ye(O);
              },
              className: "flex flex-col gap-4",
              children: [
                /* @__PURE__ */ e("div", { children: y ? /* @__PURE__ */ p("p", { children: [
                  n("login-form:bodyWelcomeBack"),
                  /* @__PURE__ */ e("br", {}),
                  n("login-form:bodyPasswordRequest")
                ] }) : /* @__PURE__ */ e("p", { children: n("login-form:bodyUnsureAccount") }) }),
                /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
                  de,
                  {
                    type: "email",
                    label: n("common:EmailAddress"),
                    error: (z = m.formState.errors.email) == null ? void 0 : z.message,
                    autoCapitalize: "off",
                    autoComplete: "email",
                    disabled: y,
                    description: P ? /* @__PURE__ */ p("span", { role: "alert", children: [
                      n("common:DidYouMean"),
                      " ",
                      /* @__PURE__ */ p(
                        "button",
                        {
                          className: $(
                            "underline",
                            "gamma:rounded-md outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                          ),
                          onClick: oe,
                          children: [
                            "@",
                            P.domain
                          ]
                        }
                      ),
                      "?"
                    ] }) : void 0,
                    icon: y && /* @__PURE__ */ e(
                      "button",
                      {
                        className: $(
                          "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                          "outline-none ring-offset-1 focus-visible:ring-2 focus-visible:ring-ignew-functional-focus-400"
                        ),
                        "data-testid": "login-change-email",
                        onClick: (O) => {
                          O.preventDefault(), E({
                            type: "login_interaction",
                            data: {
                              user_selected_element: "Login pop-up change e-mail link click"
                            }
                          }), m.reset(), T(!1);
                        },
                        type: "button",
                        children: /* @__PURE__ */ e(pe, {})
                      }
                    ),
                    ...m.register("email", {
                      onBlur(O) {
                        nt(
                          O.target.value,
                          G
                        );
                      }
                    })
                  }
                ) }) }) }),
                /* @__PURE__ */ e(
                  "div",
                  {
                    className: y ? "" : "h-0 overflow-hidden absolute",
                    children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
                      Re,
                      {
                        ...!y && {
                          tabIndex: -1,
                          "aria-hidden": "true"
                        },
                        autoComplete: y ? "current-password" : "off",
                        label: n("ig-header:loginPasswordLabel"),
                        error: ((Q = m.formState.errors.password) == null ? void 0 : Q.message) || t,
                        ...m.register("password")
                      }
                    ) })
                  }
                ),
                Y && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
                  fe,
                  {
                    control: m.control,
                    name: "requirements",
                    defaultValue: !1,
                    render: ({ field: O, fieldState: Ce }) => {
                      var Pe;
                      return /* @__PURE__ */ e(
                        _e,
                        {
                          ref: O.ref,
                          name: O.name,
                          checked: O.value,
                          onCheckedChange: (Ve) => {
                            Ve !== "indeterminate" && O.onChange(Ve);
                          },
                          error: (Pe = Ce.error) == null ? void 0 : Pe.message,
                          label: /* @__PURE__ */ p(Ee, { ns: "login", i18nKey: "requirements", children: [
                            "Met het aanmaken van een account, ga ik akkoord met de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: ie,
                                rel: "noreferrer",
                                children: "voorwaarden"
                              }
                            ),
                            "en profiteer ik van alle gratis voordelen van de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: Z,
                                rel: "noreferrer",
                                children: "pas"
                              }
                            )
                          ] })
                        }
                      );
                    }
                  }
                ) }),
                (K == null ? void 0 : K.message) && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: K.message }),
                /* @__PURE__ */ e("div", { children: y ? /* @__PURE__ */ e(
                  X,
                  {
                    loading: r,
                    className: "w-full text-center",
                    "data-testid": "login-submit",
                    type: "submit",
                    children: n("ig-header:Login")
                  }
                ) : /* @__PURE__ */ e(
                  X,
                  {
                    loading: h,
                    className: "w-full text-center",
                    onClick: () => We(ye),
                    "data-testid": "login-next",
                    children: n("login-form:buttonlabel")
                  }
                ) }),
                y && C && /* @__PURE__ */ p("div", { className: "text-center", children: [
                  /* @__PURE__ */ e("div", { className: "mb-4", children: /* @__PURE__ */ e(
                    "button",
                    {
                      className: $(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: (O) => {
                        O.preventDefault(), E({
                          type: "login_interaction",
                          data: {
                            user_selected_element: "Login pop-up password reset link click"
                          }
                        }), b({
                          flow: k.FORGOT_PASSWORD,
                          forgotPasswordData: {
                            email: m.getValues().email,
                            customerId: C.customerId
                          }
                        });
                      },
                      children: n("ig-header:loginForgotPassword")
                    }
                  ) }),
                  /* @__PURE__ */ e(
                    "button",
                    {
                      className: $(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: () => {
                        Ae(m.getValues().email);
                      },
                      children: n("login-form:loginWithCodeLink")
                    }
                  )
                ] })
              ]
            }
          ),
          typeof i < "u" && /* @__PURE__ */ e(
            wt,
            {
              onSubmit: i,
              isLoading: r
            }
          )
        ] });
      case ee.RESEND_VERIFICATION:
        return /* @__PURE__ */ p(te, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("login-form:loginTitle") }),
          /* @__PURE__ */ e(
            pt,
            {
              email: g.email,
              goToStep: V,
              goToFlow: b
            }
          )
        ] });
      case ee.RESEND_SUCCESS:
        return /* @__PURE__ */ p(te, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("register:titleIsThatYou") }),
          /* @__PURE__ */ e(ht, { email: g.email })
        ] });
      default:
        return null;
    }
  })(v) });
}
function yt() {
  const { MYACCOUNT_URL: r } = j(), { setFlow: s, setSignUpResponse: t, setVerificationError: a } = ae(), { t: i } = R(["register", "errors", "login"]), c = W(() => {
    re.addAction("verified_code");
  }, []), o = W((d) => {
    re.addAction("verified_code_failed", {
      errorDetails: d == null ? void 0 : d.message
    });
  }, []), n = se(
    () => le(r),
    [r]
  );
  function u({ verificationCode: d, customerId: l }) {
    return n.verify("signup", `${l}_${d}`, l).catch((f) => {
      var g;
      if (ve(f)) {
        const w = (g = f.response) == null ? void 0 : g.data;
        if (w.status === "VerifySignUpInvalidToken")
          if (w.httpStatus === 410)
            t(void 0), a(i("register:tooManyWrongAttempts")), s({ flow: k.SIGN_UP });
          else
            throw new F(i("register:errorNotValidCode"));
      }
      throw ge.error("Verify code form failed", {
        errorDetails: f == null ? void 0 : f.message
      }), (f == null ? void 0 : f.response.status) === 429 ? new F(i("errors:tooManyVerificationAttempts")) : new F(
        `${i("errors:somethingWentWrong")} ${i(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me(u, {
    useErrorBoundary: !1,
    onSuccess: () => {
      E({
        type: "verify_email"
      }), c();
    },
    onError: (d) => {
      E({
        type: "verify_email_error",
        data: {
          error: d == null ? void 0 : d.message
        }
      }), o(d);
    }
  });
}
const Be = (r) => /^\d+$/.test(r);
function Ct() {
  const [r, s] = S(!1), t = W(async (a) => {
    s(!0);
    try {
      return await ot({
        username: a,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
    } catch {
      return null;
    } finally {
      s(!1);
    }
  }, []);
  return {
    isLoading: r,
    requestVerificationCode: t
  };
}
function St({
  navigateToLogin: r = !1,
  email: s,
  customerId: t
}) {
  const { setFlow: a, setLoginAfterSignUp: i } = ae(), { t: c } = R(["verification", "common", "urls"]);
  return I(() => {
    be.success(c("verification:signupVerificationSuccessDescription"), {
      icon: Ie
    }), a({ flow: k.LOGIN }), i(!0), E({
      type: "sign_up_activation",
      data: { kdbid: t }
    });
  }, [c, a, i, t]), r ? /* @__PURE__ */ e(
    Xe,
    {
      to: { pathname: c("urls:/login"), search: "type=new" },
      state: { email: s },
      replace: !0
    }
  ) : null;
}
const Se = 4;
function Nt({
  verificationData: r,
  navigateToLogin: s = !1,
  onLogin: t,
  confirmSignInError: a
}) {
  const { customerId: i, email: c, control: o = "A", loginViaCode: n } = r, { t: u } = R(["register", "common", "login-form"]), [d, l] = S(""), [f, g] = S(null), [w, b] = S(!1), N = ce(null), L = ce(null), [B, A] = S(!1), { setFlow: y, signUpRequestBody: T, setVerificationError: P } = ae(), {
    isLoading: G,
    requestVerificationCode: Y
  } = Ct(), q = ce(t), {
    mutate: v,
    error: V,
    data: _
  } = $e(), {
    mutate: ie,
    isSuccess: Z,
    isError: C,
    error: H
  } = yt();
  I(() => {
    var m;
    (m = document.getElementById("code")) == null || m.focus();
  }, [G]), I(() => {
    n && Y(i);
  }, [n, i, Y]), I(() => {
    C && A(!1);
  }, [C]), I(() => {
    var m;
    Z && q.current && ((m = T.password) != null && m.length) ? (E({
      type: "sign_up_activation",
      data: { kdbid: i }
    }), q.current({
      body: {
        email: c,
        customerId: i,
        password: T.password
      },
      disableAdobeEvent: !0
    })) : A(!1);
  }, [Z, q, T, i, c]);
  const ne = (m) => {
    if (m == null || m.stopPropagation(), m == null || m.preventDefault(), P(void 0), N.current && clearTimeout(N.current), !(d.length === Se)) {
      g(u("register:errorNotValidCode"));
      return;
    }
    i && !n && (g(null), A(!0), ie({ verificationCode: d, customerId: i })), n && q.current && (g(null), A(!0), q.current({
      body: {
        email: c,
        customerId: i,
        verificationCode: d
      }
    }));
  };
  I(() => {
    var m;
    return d.length === Se && Be(d) ? ((m = L.current) == null || m.blur(), N.current = setTimeout(() => {
      ne();
    }, 1e3)) : N.current && clearTimeout(N.current), () => {
      N.current && clearTimeout(N.current);
    };
  }, [d]), I(() => {
    const m = (H == null ? void 0 : H.message) || (V == null ? void 0 : V.message);
    m && (g(m), setTimeout(() => {
      var oe;
      (oe = document.getElementById("code")) == null || oe.focus();
    }, 1));
  }, [V, H]);
  const K = (m) => {
    const { value: oe } = m.target, ye = oe.replace(/\D/g, "");
    l(ye);
  }, h = (m) => {
    m.preventDefault(), l(""), g(null), r.loginViaCode ? Y(i) : v({
      email: c
    }), be.success(u("register:successMessageResendCode"), { icon: Ie }), b(!0), setTimeout(() => {
      b(!1);
    }, 3e4);
  }, M = (m) => {
    y({
      flow: k.LOGIN,
      loginData: {
        email: m,
        // If a user has no password it would not show the password input.
        // This forces the input to show up.
        forcePasswordFlow: !0
      }
    });
  };
  if (I(() => {
    var m;
    a && (A(!1), g(a), (m = L.current) == null || m.focus());
  }, [a, f, u, y]), Z && !B)
    return /* @__PURE__ */ e(
      St,
      {
        navigateToLogin: s,
        email: c,
        customerId: i
      }
    );
  const D = (_ == null ? void 0 : _.control) || o;
  return /* @__PURE__ */ p("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: u(n ? "login-form:loginCodeSentTitle" : "register:titleIsThatYou") }),
    /* @__PURE__ */ e("div", { children: u(n ? "login-form:loginCodeSentDescription" : "register:bodyCodeSentTo") }),
    /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
      de,
      {
        label: "",
        value: c,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: $(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => {
              y({ flow: k.LOGIN }), E({ type: "sign_up_change_email" });
            },
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ e("p", { children: u(n ? "login-form:codeValidity" : "register:bodyFillCode") }),
    /* @__PURE__ */ p(
      "form",
      {
        className: "flex flex-col gap-4",
        noValidate: !0,
        onSubmit: ne,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Fe,
            {
              label: u("login-form:verifyNewPasswordCodeLabel"),
              value: d,
              "data-testid": "code",
              onChange: K,
              autoFocus: !0,
              disabled: G,
              maxLength: Se,
              controlCharacter: D,
              description: u("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${D}`
              }
            }
          ),
          f && /* @__PURE__ */ e(ue, { variant: "error", children: f }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            X,
            {
              type: "submit",
              className: "w-full",
              loading: B || G,
              children: u(B ? "register:isVerifying" : "register:confirm")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: $(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: w,
              onClick: (m) => h(m),
              children: u("register:buttonLabelResendCode")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: $(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              onClick: () => {
                M(c);
              },
              children: u("login-form:loginPasswordLink")
            }
          ) })
        ]
      }
    )
  ] });
}
function Ge() {
  const { MYACCOUNT_URL: r } = j(), { t: s } = R(["register", "errors"]), t = se(
    () => le(r),
    [r]
  ), a = W(() => {
    re.addAction("request_new_password");
  }, []), i = W(
    (o) => {
      re.addAction("request_new_password_failed", {
        reason: o
      });
    },
    []
  );
  function c(o) {
    return t.passwordReset({ email: o, verificationMethod: Te.CODE }).catch((n) => {
      throw ge.error("Reset password request failed", {
        errorDetails: n == null ? void 0 : n.message
      }), (n == null ? void 0 : n.response.status) === 429 ? new F(s("errors:tooManyVerificationAttempts")) : new F(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me(c, {
    useErrorBoundary: !1,
    onSuccess: () => {
      a();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
function _t({ email: r, onSuccess: s }) {
  var g;
  const { t } = R([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), a = U.object({
    password: U.string().min(8, t("register:minPasswordLength")).max(50, t("register:maxPasswordLength"))
  }), i = he({
    defaultValues: {
      password: ""
    },
    resolver: we(a)
  }), c = i.watch("password"), o = se(
    () => Oe(c),
    [c]
  ), n = Ue(o), { mutate: u, isLoading: d, error: l } = Ge(), f = i.handleSubmit((w) => {
    u(r, {
      onSuccess({ control: b }) {
        s(w.password, b);
      }
    });
  });
  return /* @__PURE__ */ p(
    "form",
    {
      noValidate: !0,
      onSubmit: (w) => {
        w.stopPropagation(), f(w);
      },
      className: "flex flex-col gap-4",
      children: [
        /* @__PURE__ */ p("div", { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: t("login-form:chooseNewPasswordTitle") }),
          /* @__PURE__ */ e("p", { children: t("login-form:chooseNewPasswordBody") })
        ] }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
          Re,
          {
            autoFocus: !0,
            autoComplete: "new-password",
            label: t("login-form:Password"),
            error: (g = i.formState.errors.password) == null ? void 0 : g.message,
            passwordStrength: o,
            description: n,
            ...i.register("password")
          }
        ) }) }),
        (l == null ? void 0 : l.message) && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(ke, {}), children: l.message }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
          X,
          {
            disabled: d,
            loading: d,
            className: "w-full text-center",
            children: t("login-form:buttonlabel")
          }
        ) })
      ]
    }
  );
}
function Et() {
  const { MYACCOUNT_URL: r } = j(), { t: s } = R(["register", "errors"]), t = se(
    () => le(r),
    [r]
  ), a = W(() => {
    re.addAction("verified_new_password");
  }, []), i = W((o) => {
    re.addAction("verifiy_new_password_failed", {
      reason: o
    });
  }, []);
  function c(o) {
    return t.verifyPasswordReset(o).catch((n) => {
      var u;
      if (ve(n)) {
        const d = (u = n.response) == null ? void 0 : u.data;
        if (d.status === "VerifyPasswordResetInvalidToken")
          throw d.httpStatus === 410 ? new F(s("register:tooManyWrongAttempts")) : new F(s("register:errorNotValidCode"));
      }
      throw ge.error("Verify code form failed", {
        errorDetails: n == null ? void 0 : n.message
      }), (n == null ? void 0 : n.response.status) === 429 ? new F(s("errors:tooManyVerificationAttempts")) : new F(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me(c, {
    useErrorBoundary: !1,
    onSuccess: () => {
      E({ type: "login_change_password" }), a();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
const Ne = 4;
function Rt({
  email: r,
  password: s,
  customerId: t,
  control: a,
  onSuccess: i
}) {
  const { t: c } = R([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [o, n] = S(""), [u, d] = S(null), l = ce(null), f = ce(null), { setFlow: g } = ae(), {
    mutate: w,
    isLoading: b,
    error: N
  } = Et(), {
    data: L,
    mutate: B,
    error: A
  } = Ge(), [y, T] = S(!1), P = (v) => {
    const { value: V } = v.target, _ = V.replace(/\D/g, "");
    n(_);
  }, G = (v) => {
    if (v == null || v.stopPropagation(), v == null || v.preventDefault(), l.current && clearTimeout(l.current), !(o.length === Ne)) {
      d(c("register:errorNotValidCode"));
      return;
    }
    w({ newPassword: s, customerId: t, code: o }, { onSuccess: i });
  };
  I(() => {
    var v;
    return o.length === Ne && Be(o) ? ((v = f.current) == null || v.blur(), l.current = setTimeout(() => {
      G();
    }, 1e3)) : l.current && clearTimeout(l.current), () => {
      l.current && clearTimeout(l.current);
    };
  }, [o]), I(() => {
    const v = (N == null ? void 0 : N.message) || (A == null ? void 0 : A.message);
    v && (d(v), setTimeout(() => {
      var V;
      (V = f.current) == null || V.focus();
    }, 1));
  }, [A, N]);
  const Y = (v) => {
    v.preventDefault(), n(""), B(r, {
      onSuccess() {
        be.success(c("register:successMessageResendCode"), {
          icon: Ie
        }), d(null);
      }
    }), T(!0), setTimeout(() => {
      T(!1);
    }, 3e4);
  }, q = (L == null ? void 0 : L.control) || a;
  return /* @__PURE__ */ p(te, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: c("login-form:verifyNewPasswordTitle") }),
    /* @__PURE__ */ e("div", { className: "mb-4", children: c("login-form:verifyNewPasswordBody") }),
    /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
      de,
      {
        label: "",
        value: r,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: $(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => g({ flow: k.LOGIN }),
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ p(
      "form",
      {
        className: "flex flex-col gap-4 mt-4",
        noValidate: !0,
        onSubmit: G,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Fe,
            {
              label: c("login-form:verifyNewPasswordCodeLabel"),
              value: o,
              "data-testid": "code",
              autoFocus: !0,
              onChange: P,
              disabled: b,
              maxLength: Ne,
              controlCharacter: q,
              description: c("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${q}`
              }
            }
          ),
          u && /* @__PURE__ */ e(ue, { variant: "error", children: u }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { className: "w-full", loading: b, children: c(b ? "register:isVerifying" : "register:confirm") }) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              className: $(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: y,
              onClick: (v) => Y(v),
              children: c("register:buttonLabelResendCode")
            }
          ) })
        ]
      }
    )
  ] });
}
function It({
  values: r,
  onLogin: s
}) {
  const [t, a] = S({
    step: "CHOOSE_PASSWORD",
    email: r.email
  }), { t: i } = R(["change-password"]);
  return /* @__PURE__ */ p(te, { children: [
    t.step === "CHOOSE_PASSWORD" && /* @__PURE__ */ e(
      _t,
      {
        email: t.email,
        onSuccess: (c, o) => {
          a({
            step: "VERIFY_REQUEST",
            email: r.email,
            password: c,
            control: o
          });
        }
      }
    ),
    t.step === "VERIFY_REQUEST" && /* @__PURE__ */ e(
      Rt,
      {
        customerId: r.customerId,
        email: t.email,
        password: t.password,
        control: t.control,
        onSuccess: () => {
          be.success(i("change-password:successfulSave")), s({
            body: {
              email: t.email,
              customerId: r.customerId,
              password: t.password
            },
            disableAdobeEvent: !0
          });
        }
      }
    )
  ] });
}
function rr(r) {
  const [s, t] = S({ flow: k.LOGIN }), [a, i] = S(), [c, o] = S(void 0), { i18n: n } = R(), [u, d] = S(!1), [l, f] = S(
    {
      email: "",
      password: "",
      locale: Le(n.language),
      optIn: !1,
      verificationMethod: "CODE",
      passwordless: !1
    }
  );
  return /* @__PURE__ */ e("div", { className: "px-4 py-6", children: /* @__PURE__ */ p(dt, { value: {
    signUpRequestBody: l,
    setSignUpRequestBody: f,
    setFlow: t,
    loginAfterSignUp: u,
    setLoginAfterSignUp: d,
    signUpResponse: a,
    setSignUpResponse: i,
    setVerificationError: o,
    verificationError: c
  }, children: [
    s.flow === k.LOGIN && /* @__PURE__ */ e(bt, { ...r, loginData: s.loginData }),
    s.flow === k.SIGN_UP && /* @__PURE__ */ e(
      ft,
      {
        setShowLoyaltyBenefits: r.setShowLoyaltyBenefits
      }
    ),
    s.flow === k.VERIFY && /* @__PURE__ */ e(
      Nt,
      {
        verificationData: s.verificationData,
        navigateToLogin: r.isMyAccount,
        confirmSignInError: r.errorMessage,
        onLogin: r.onLogin
      }
    ),
    s.flow === k.FORGOT_PASSWORD && /* @__PURE__ */ e(
      It,
      {
        values: s.forgotPasswordData,
        onLogin: r.onLogin
      }
    )
  ] }) });
}
function sr({
  loyaltyCardNumber: r,
  close: s
}) {
  const { t, i18n: a } = R(["urls", "loyalty-benefits"]), { brand: i, MYACCOUNT_URL: c } = j(), o = Ze(), n = () => {
    s(), o(t("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, u = `${c}/api/app/loyalty/cards/apple-wallet-pass`, d = at() === lt.IOS, l = async (f) => {
    const g = f.currentTarget;
    if (!(g != null && g.href))
      try {
        const w = await rt(), N = await (await fetch(u, {
          headers: {
            ...w,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), L = window.URL.createObjectURL(N);
        g.download = `${i}.pkpass`, g.href = L, g.click(), g.removeAttribute("href"), g.removeAttribute("download"), window.URL.revokeObjectURL(L);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
  return /* @__PURE__ */ p("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ p("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: t("loyalty-benefits:titleCongratsNewVdp") }),
      d && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: t("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ p("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(ze, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ p(Ee, { t, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      it,
      {
        language: a.language,
        cardNumber: r
      }
    ) }) }),
    d && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: l, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: a.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(X, { onClick: n, className: "w-full", children: t("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  rr as A,
  k as F,
  sr as L,
  ht as R,
  ft as S,
  Nt as V,
  qe as a,
  dt as b,
  bt as c,
  wt as d,
  pt as e,
  St as f,
  Me as g,
  De as h,
  ct as i,
  $e as j,
  ut as k,
  yt as l,
  ee as m,
  Te as n,
  ae as u
};
