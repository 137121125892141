import {
  ComponentProps,
  ComponentPropsWithRef,
  ReactNode,
  forwardRef,
  useId,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Description } from "./Description";
import { Error } from "./Error";
import { Label } from "./Label";
import { inputStyles } from "./styles";

type TextareaFieldProps = ComponentPropsWithRef<"textarea"> & {
  label: string;
  description?: ReactNode;
  error?: ReactNode;
  info?: ComponentProps<typeof Label>["info"];
  icon?: ReactNode;
  optional?: ComponentProps<typeof Label>["optional"];
};

export const TextareaField = forwardRef<
  HTMLTextAreaElement,
  TextareaFieldProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function TextareaField(
    { className, label, description, error, info, optional, ...inputProps },
    ref,
  ) {
    const id = useId();

    return (
      <div className={cn("flex flex-col", className)}>
        <Label htmlFor={id} info={info} optional={optional}>
          {label}
        </Label>
        <div className="relative">
          <textarea
            id={id}
            ref={ref}
            className={cn(inputStyles({ error }), "h-auto py-4")}
            {...inputProps}
          />
        </div>
        {error && <Error className="mt-1">{error}</Error>}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
