import { Amplify as C } from "aws-amplify";
import { CookieStorage as S } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider as y } from "aws-amplify/auth/cognito";
import { useMemo as I, useState as w } from "react";
import { dispatchAdobeEvent as m } from "@intergamma/adobe-tracking";
import { signIn as P, confirmSignIn as k } from "aws-amplify/auth";
import { useQueryClient as A } from "@tanstack/react-query";
import { useTranslation as L } from "react-i18next";
import { l as p } from "./datadog-logging-CPRR9qp_.js";
import { useConfig as T } from "@intergamma/config";
import { c as b } from "./index-C2EW4nef.js";
const N = (r) => {
  const e = typeof document < "u" && document.location.href.match(/\/\/([^:/]+)/), t = (e ? e[1] : "").indexOf("acceptatie") < 0;
  switch (r) {
    case "gamma_be":
      return {
        userPoolId: t ? "eu-west-1_KwrkK0ufX" : "eu-west-1_j0JUmjRMW",
        // GAMMA BE ACC - USER POOL ID
        userPoolClientId: t ? "6bi3hbinnkcq1h12ctlige9f08" : "1te9tbna7h0sd6bemnevublnts"
        // GAMMA BE ACC - CLIENT ID
      };
    case "karwei_nl":
      return {
        userPoolId: t ? "eu-west-1_Ogv9zM2Zp" : "eu-west-1_AzWkTXNK8",
        // KARWEI ACC - USER POOL ID
        userPoolClientId: t ? "6lqki7p47e0q3etbn87pvb56op" : "7crtb2r6sfb9spi854qq0qcp1b"
        // KARWEI ACC - CLIENT ID
      };
    case "gamma_nl":
    default:
      return {
        userPoolId: t ? "eu-west-1_OHzUYP2T4" : "eu-west-1_Sm2PRyfmS",
        // GAMMA NL ACC - USER POOL ID
        userPoolClientId: t ? "78km7bhfrsa50ajiebbi8ng65q" : "64pji0q6h1armolasivbn51ftq"
        // GAMMA NL ACC - CLIENT ID
      };
  }
};
function K(r) {
  C.configure({
    Auth: {
      Cognito: N(r)
    }
  });
  const e = window.location.hostname.includes("acceptatie") ? ".acceptatie" : "";
  let o = ".gamma.nl";
  r === "gamma_be" && (o = ".gamma.be"), r === "karwei_nl" && (o = ".karwei.nl"), y.setKeyValueStorage(
    new S({
      domain: `${e}${o}`
    })
  );
}
function U(r) {
  const e = b(r, {
    withCredentials: !0
  });
  return {
    async getGatewayCSRF() {
      return (await e.get(
        "/gateway/session"
      )).csrfToken;
    },
    async login(o) {
      const t = await this.getGatewayCSRF();
      return e.post("/gateway/addcard/login", o, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    }
  };
}
const M = () => {
  const { MYACCOUNT_URL: r } = T(), e = I(
    () => U(r),
    [r]
  );
  return {
    legacyLogin: (t) => new Promise((d, c) => {
      e.login(t).then(() => d()).catch(() => c());
    })
  };
};
function D(r = "loginHeader") {
  const { t: e } = L(["ig-header", "register", "login-form"]), [o, t] = w(!1), [d, c] = w(), f = A(), { legacyLogin: _ } = M();
  return {
    authenticate: ({
      body: i,
      disableAdobeEvent: h
    }) => (t(!0), i.password ? new Promise((l, u) => {
      P({
        username: i.customerId,
        password: i.password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      }).then(() => (h || m({
        type: r === "loginHeader" ? "login_header" : "login",
        data: {
          kdbid: i.customerId
        }
      }), t(!1), f.resetQueries(), l())).catch((g) => {
        const { name: n, message: a } = g;
        _({ email: i.email, password: i.password }).then(() => (t(!1), f.resetQueries(), l)).catch(() => {
          p.error("Login failed", {
            errorDetails: `${n}: ${a}`
          }), m({
            type: r === "loginHeader" ? "login_error_header" : "login_error",
            data: {
              user_message_type: `${n}: ${a}`
            }
          });
          let s = e("ig-header:loginSomethingWentWrong");
          return n === "NotAuthorizedException" && (s = e("ig-header:loginInvalidPassword"), a === "Password attempts exceeded" && (s = e("ig-header:loginTooManyAttempts"))), n === "UserNotFoundException" && (s = e("ig-header:loginUserNotFound")), t(!1), c(s), u();
        });
      });
    }) : new Promise((l, u) => {
      k({
        challengeResponse: i.verificationCode ?? ""
      }).then((g) => {
        const { isSignedIn: n, nextStep: a } = g;
        return t(!1), n ? (p.info("Login via code successful"), h || m({
          type: r === "loginHeader" ? "login_header" : "login",
          data: {
            kdbid: i.customerId
          }
        }), f.resetQueries(), l()) : (a.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE" && c(e("register:errorNotValidCode")), u());
      }).catch((g) => {
        const { name: n, message: a } = g;
        p.error("Login via code failed", {
          errorDetails: `${n}: ${a}`
        }), m({
          type: r === "loginHeader" ? "login_error_header" : "login_error",
          data: {
            user_message_type: `${n}: ${a}`
          }
        });
        let s = e("ig-header:loginSomethingWentWrong");
        return n === "NotAuthorizedException" && (s = e("login-form:loginCodeMaxAttempts")), t(!1), c(s), u();
      });
    })),
    error: d,
    isLoading: o
  };
}
export {
  K as c,
  N as g,
  D as u
};
