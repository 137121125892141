import { forwardRef, type SVGProps } from "react";

export interface TrashIconProps extends SVGProps<SVGSVGElement> {}

export const Trash = forwardRef<SVGSVGElement, TrashIconProps>(
  ({ className, ...props }, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M9.25 8.75C8.83579 8.75 8.5 9.08579 8.5 9.5V17.5C8.5 17.9142 8.83579 18.25 9.25 18.25C9.66421 18.25 10 17.9142 10 17.5V9.5C10 9.08579 9.66421 8.75 9.25 8.75Z"
        fill="currentColor"
      />
      <path
        d="M11.25 9.5C11.25 9.08579 11.5858 8.75 12 8.75C12.4142 8.75 12.75 9.08579 12.75 9.5V17.5C12.75 17.9142 12.4142 18.25 12 18.25C11.5858 18.25 11.25 17.9142 11.25 17.5V9.5Z"
        fill="currentColor"
      />
      <path
        d="M14.75 8.75C14.3358 8.75 14 9.08579 14 9.5V17.5C14 17.9142 14.3358 18.25 14.75 18.25C15.1642 18.25 15.5 17.9142 15.5 17.5V9.5C15.5 9.08579 15.1642 8.75 14.75 8.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 4.75V4.25C7.75 2.73122 8.98122 1.5 10.5 1.5H13.5C15.0188 1.5 16.25 2.73122 16.25 4.25V4.75H19.25C19.6642 4.75 20 5.08579 20 5.5C20 5.91421 19.6642 6.25 19.25 6.25H18.75V20.75C18.75 21.7165 17.9665 22.5 17 22.5H7C6.0335 22.5 5.25 21.7165 5.25 20.75V6.25H4.75C4.33579 6.25 4 5.91421 4 5.5C4 5.08579 4.33579 4.75 4.75 4.75H7.75ZM9.25 4.25C9.25 3.55964 9.80964 3 10.5 3H13.5C14.1904 3 14.75 3.55964 14.75 4.25V4.75H9.25V4.25ZM17.25 6.25V20.75C17.25 20.8881 17.1381 21 17 21H7C6.86193 21 6.75 20.8881 6.75 20.75V6.25H17.25Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(Trash as React.FC).displayName = "Trash";
