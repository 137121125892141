import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import { useTranslation } from "react-i18next";

export enum PasswordStrength {
  UNDEFINED = -1,
  SHORT = 1,
  WEAK = 2,
  AVERAGE = 3,
  STRONG = 4,
}

export const loadOptions = async () => {
  const { pathname } = window.location;

  let zxcvbnFrPackage;
  let zxcvbnNLPackage;

  if (pathname.startsWith("/fr")) {
    zxcvbnFrPackage = await import("@zxcvbn-ts/language-fr");
  } else {
    zxcvbnNLPackage = await import("@zxcvbn-ts/language-nl-be");
  }

  const zxcvbnCommonPackage = await import("@zxcvbn-ts/language-common");

  const options = {
    dictionary: {
      ...zxcvbnCommonPackage.default.dictionary,
      ...(zxcvbnNLPackage?.default.dictionary ?? {}),
      ...(zxcvbnFrPackage?.default.dictionary ?? {}),
    },
  };

  zxcvbnOptions.setOptions(options);
};

export function calculatePasswordStrength(password: string): PasswordStrength {
  if (!password) {
    return PasswordStrength.UNDEFINED;
  }

  if (password.length < 8) {
    return PasswordStrength.SHORT;
  }

  const { score } = zxcvbn(password);

  switch (score) {
    case 0:
    case 1:
      return PasswordStrength.WEAK;
    case 2:
      return PasswordStrength.AVERAGE;
    case 3:
    case 4:
      return PasswordStrength.STRONG;
    default:
      return PasswordStrength.UNDEFINED;
  }
}

export function useStrengthText(strength: PasswordStrength) {
  const { t } = useTranslation("ig-form");

  if (strength === PasswordStrength.SHORT) {
    return t("pwTooShort");
  }

  if (
    strength === PasswordStrength.WEAK ||
    strength === PasswordStrength.AVERAGE
  ) {
    return t("pwOkEnough");
  }

  if (strength === PasswordStrength.STRONG) {
    return t("pwGoodEnough");
  }

  return "";
}
