import { jsx as o } from "react/jsx-runtime";
import { forwardRef as i } from "react";
import { cn as s } from "@intergamma/common/cn";
import a from "downshift";
function c({
  children: r,
  ...t
}) {
  return /* @__PURE__ */ o(a, { ...t, children: (e) => /* @__PURE__ */ o(
    p,
    {
      ...e.getRootProps({ refKey: "ref" }),
      isOpen: e.isOpen,
      children: r(e)
    }
  ) });
}
const p = i(
  ({
    isOpen: r,
    children: t,
    ...e
  }, n) => /* @__PURE__ */ o(
    "div",
    {
      ...e,
      className: s("relative", r ? "z-[500]" : "z-auto"),
      ref: n,
      children: t
    }
  )
), l = i(
  ({ isOpen: r, children: t, ...e }, n) => /* @__PURE__ */ o(
    "div",
    {
      ...e,
      className: s(
        "absolute z-[501] w-full overflow-hidden overflow-y-auto bg-white duration-100",
        r ? "left-auto animate-downshift-fade-slide-in" : "left-[-9999px]",
        "md:border md:border-brand-primary",
        "rounded-[23px] p-[3px]",
        "lg:peer-focus:w-[600px]",
        "border-opacity-0 ring-opacity-0",
        "peer-focus:outline-none peer-focus:ring-offset-0 md:peer-focus:ring-2 md:peer-focus:ring-opacity-50",
        "peer-focus:ring-brand-primary/20",
        "md:right-0",
        "gamma:top-[46px] karwei:top-[40px]"
      ),
      ref: n,
      children: t
    }
  )
), m = i(({ children: r, ...t }, e) => /* @__PURE__ */ o("ul", { ref: e, ...t, className: s("relative pt-[17px]"), children: r })), b = ({
  highlighted: r,
  children: t,
  ...e
}) => /* @__PURE__ */ o(
  "li",
  {
    ...e,
    className: s(
      "relative m-0 cursor-pointer border-b",
      r ? "underline md:bg-brand-primary/10 md:no-underline" : "bg-transparent",
      "border-b-ignew-neutral-400",
      "rounded-[20px] border-none py-2.5 md:px-2.5",
      "[&:last-child]:border-none",
      "[&_.suggestion-type]:absolute [&_.suggestion-type]:right-[16px] [&_.suggestion-type]:text-[13px]",
      "[&_.suggestion-type]:text-ignew-neutral-200",
      "[&.start-search-suggestions]:mt-[10px]",
      "[&.start-search-suggestions]:before:border-t-ignew-neutral-200",
      "[&.start-search-suggestions]:before:absolute [&.start-search-suggestions]:before:h-[1px]",
      "[&.start-search-suggestions]:before:w-[calc(100%-30px)] [&.start-search-suggestions]:before:border-t",
      "[&.start-search-suggestions]:before:top-[-5px]",
      // eslint-disable-next-line
      '[&.start-search-suggestions]:before:content-[""]'
    ),
    children: t
  }
);
export {
  c as Downshift,
  b as DownshiftItem,
  m as DownshiftItems,
  l as DownshiftMenu
};
