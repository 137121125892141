import { jsx as o } from "react/jsx-runtime";
const m = ({
  children: e,
  ...t
}) => /* @__PURE__ */ o("ul", { ...t, children: e }), s = ({ children: e, ...t }) => /* @__PURE__ */ o(
  "li",
  {
    ...t,
    className: "relative mb-2.5 pl-[14px] leading-[1.4em] before:absolute before:left-0 before:content-['›'] last:mb-0 md:mb-[3px]",
    children: e
  }
);
export {
  m as List,
  s as ListItem
};
