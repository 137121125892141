import { jsx as g } from "react/jsx-runtime";
import { cn as t } from "@intergamma/common";
import * as r from "@radix-ui/react-dialog";
const a = ({
  children: o,
  className: i,
  ...e
}) => /* @__PURE__ */ g(
  r.Trigger,
  {
    ...e,
    className: t(
      "h-auto cursor-pointer border-none bg-transparent focus:shadow-none",
      i
    ),
    children: o
  }
), m = r.Trigger;
export {
  a as DialogTrigger,
  m as UnstyledDialogTrigger
};
