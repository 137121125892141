import { jsx as t, jsxs as a } from "react/jsx-runtime";
import { WithoutDevices as s } from "@intergamma/devices";
import m from "styled-components";
import { useLegacyTheme as n } from "@intergamma/theme/provider";
import { useTranslation as r } from "react-i18next";
import { D as c, C as g, g as f, N as u } from "./NavigationFooterInner-cCJ63XRM.js";
function l() {
  const { t: o } = r("ig-footer"), { name: e } = n();
  return /* @__PURE__ */ t(c, { t: o, legacyThemeBrand: e });
}
function h() {
  const { t: o } = r("ig-footer");
  return /* @__PURE__ */ t(g, { t: o });
}
function p() {
  const { name: o } = n(), { t: e } = r("ig-footer"), i = f({
    t: e,
    legacyThemeBrand: o
  });
  return /* @__PURE__ */ t(u, { navigation: i });
}
function T({ children: o }) {
  return /* @__PURE__ */ t(s, { devices: ["app"], children: /* @__PURE__ */ a(d, { children: [
    /* @__PURE__ */ t(l, {}),
    /* @__PURE__ */ t(h, {}),
    /* @__PURE__ */ t(p, {}),
    o
  ] }) });
}
const d = m.footer`
  line-height: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
`;
export {
  T as Footer
};
