import { lazy as r, Suspense as n } from "react";
import { jsx as m } from "react/jsx-runtime";
import { LegacyThemeProvider as h } from "@intergamma/theme/provider";
const i = r(
  () => import("./GammaNLTheme-D_QGwrIQ.js").then((e) => ({
    default: e.GammaNLTheme
  }))
), l = r(
  () => import("./GammaBETheme-DZ7FiqF7.js").then((e) => ({
    default: e.GammaBETheme
  }))
), o = r(
  () => import("./KarweiTheme-BwtB4W3X.js").then((e) => ({
    default: e.KarweiTheme
  }))
);
function T({ children: e, formula: t }) {
  const a = u(t);
  return a ? /* @__PURE__ */ m(h, { formula: t, children: /* @__PURE__ */ m(n, { fallback: null, children: /* @__PURE__ */ m(a, { children: e }) }) }) : null;
}
function u(e) {
  switch (e) {
    case "gamma_nl":
      return i;
    case "gamma_be":
      return l;
    case "karwei_nl":
      return o;
    default:
      return null;
  }
}
export {
  T as IntergammaTheme
};
