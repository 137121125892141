import c, { css as t } from "styled-components";
const d = (o) => o.$small ? 34 : o.$large ? 54 : 46, n = (o) => o.$small ? 18 : o.$large ? 22 : 20, x = (o) => o.$link || o.$small ? 1 : o.$large ? 1.2 : 1.1, s = (o) => {
  const e = (d(o) - n(o)) / 2;
  return o.$inline ? o.$link ? "0" : "-1px" : o.$link ? `${Math.ceil(e)}px 0 ${Math.floor(e)}px` : "0";
}, a = (o) => (e) => {
  const r = (d(e) - n(e)) / 2, u = e.$small ? 12 : e.$large ? 24 : 18, i = o ? 1 : 0, h = Math.ceil(r + i), $ = Math.floor(r - i), b = u;
  return e.$link ? "0 1px" : e.$inline ? "1px" : `${h}px ${b}px ${$}px`;
}, l = (o) => o.$link ? 3 : o.$alternate ? o.theme.karwei ? 99 : o.theme.borderRadius : o.theme.karwei ? o.theme.borderRadius : 99, m = c.button`
  display: ${(o) => o.$inline ? "inline" : "inline-block"};
  vertical-align: ${(o) => o.$inline ? "baseline" : "top"};
  margin: ${s};
  padding: ${a(!1)};
  width: ${(o) => o.$wide ? "100%" : "auto"};
  height: auto;
  line-height: ${n}px;
  background-color: ${(o) => o.theme.buttonColor};
  background-image: linear-gradient(#00000022 100%, #00000022 100%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: ${l}px;
  text-decoration: ${(o) => o.$link ? "underline" : "none"};
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: ${x}em;
  font-weight: ${(o) => o.$link ? "inherit" : "bold"};
  font-family: ${(o) => o.$link ? o.theme.baseFont : o.theme.brandFont};
  -webkit-font-smoothing: ${(o) => o.$link ? "subpixel-antialiased" : "antialiased"};
  -moz-osx-font-smoothing: ${(o) => o.$link ? "unset" : "grayscale"};
  transition:
    background-color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    color 0.2s;
  cursor: pointer;

  & + button,
  & + a {
    margin-left: 15px;
  }

  &:focus {
    outline: 0;
    border-radius: ${l}px;
    box-shadow:
      inset 0 0 0 2px ${(o) => o.theme.buttonFocusColor},
      0 0 0 3px ${(o) => o.theme.buttonHighlightColor};
  }

  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    &:focus {
      box-shadow:
        0 4px 10px rgba(0, 0, 0, 0.15),
        inset 0 0 0 2px ${(o) => o.theme.buttonFocusColor};
    }
  }

  &:active {
    padding: ${a(!0)};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(o) => o.$primary && t`
      background-color: ${o.theme.primaryButtonColor};
      &:focus {
        box-shadow:
          inset 0 0 0 2px ${({ theme: e }) => e.buttonFocusBorderColor},
          0 0 0 3px ${({ theme: e }) => e.buttonHighlightColor};
      }
      &:hover,
      &:active {
        box-shadow:
          0 4px 10px rgba(0, 0, 0, 0.15),
          inset 0 0 0 1px ${o.theme.buttonFocusBorderColor};
        &:focus {
          box-shadow:
            0 4px 10px rgba(0, 0, 0, 0.15),
            inset 0 0 0 2px ${o.theme.buttonFocusBorderColor};
        }
      }
    `};

  ${(o) => o.$secondary && t`
      color: ${o.theme.buttonColor};
      box-shadow: inset 0 0 0 1px ${o.theme.buttonColor};
      background-color: ${o.theme.secondaryButtonColor};
      text-shadow: none;
      &:focus {
        box-shadow:
          inset 0 0 0 2px ${o.theme.buttonColor},
          0 0 0 3px ${({ theme: e }) => e.buttonHighlightColor};
      }
      &:hover,
      &:active {
        color: ${({ theme: e }) => e.buttonColor};
        box-shadow:
          0 4px 10px rgba(0, 0, 0, 0.15),
          inset 0 0 0 1px ${o.theme.buttonColor};
        &:focus {
          box-shadow:
            0 4px 10px rgba(0, 0, 0, 0.15),
            inset 0 0 0 2px ${o.theme.buttonColor};
        }
      }
    `};

  ${(o) => o.$danger && t`
      color: ${o.theme.dangerButtonColor};
      box-shadow: inset 0 0 0 1px ${o.theme.dangerButtonColor};
      background-color: ${o.theme.secondaryButtonColor};
      text-shadow: none;
      &:focus {
        box-shadow:
          inset 0 0 0 2px ${o.theme.dangerButtonColor},
          0 0 0 3px ${({ theme: e }) => e.dangerButtonFocusColor};
      }
      &:hover,
      &:active {
        color: ${({ theme: e }) => e.dangerButtonColor};
        box-shadow:
          0 4px 10px rgba(0, 0, 0, 0.15),
          inset 0 0 0 1px ${o.theme.dangerButtonColor};
        &:focus {
          box-shadow:
            0 4px 10px rgba(0, 0, 0, 0.15),
            inset 0 0 0 2px ${o.theme.dangerButtonColor};
        }
      }
    `};

  ${(o) => o.$link && t`
      color: ${o.theme.linkColor};
      background-color: transparent;
      border-radius: 3px;
      text-shadow: none;
      &:focus {
        box-shadow: 0 0 0 3px ${o.theme.linkHighlightColor};
      }
      &:hover,
      &:active {
        color: inherit;
        text-decoration: underline;
        box-shadow: none;
        &:focus {
          box-shadow: 0 0 0 3px ${o.theme.linkHighlightColor};
        }
      }
    `};

  ${(o) => o.$input && t`
      color: ${o.$active ? o.theme.brandColor : o.theme.textColor};
      background-color: transparent;
      box-sizing: border-box;
      padding-left: 10px;
      border-radius: ${o.theme.borderRadius}px;
      border: 1px solid
        ${o.$active ? o.theme.brandColor : o.theme.borderColor};
      text-shadow: none;
      font-size: 1em;
      font-weight: 700;
      height: 60px;

      &:focus {
        outline: 0;
        padding-left: 10px;
        border: 1px solid ${o.theme.brandColor};
        box-shadow: 0 0 0 3px ${({ theme: e }) => e.buttonHighlightColor};
        border-radius: ${o.theme.borderRadius}px;
        color: ${o.theme.brandColor};
      }

      &:hover,
      &:active {
        color: inherit;
        padding-left: 10px;
        text-decoration: none;
        box-shadow: none;
        border-color: ${({ theme: e }) => e.mutedColor};
        &:focus {
          box-shadow: none;
        }
      }
    `};

  ${(o) => (o.$isLoading || o.$loading) && t`
      background-size: 80% 100%;
      transition:
        background-size ${o.$duration || 8}s cubic-bezier(0.1, 0.5, 0.5, 1),
        box-shadow 0.3s;
    `};

  ${(o) => o.$complete && t`
      background-size: 100% 100%;
      transition:
        background-size 0.3s,
        box-shadow 0.3s;
    `};

  ${(o) => o.$experimentalBorderRadius && t`
    .theme-gamma & {
      border-radius: 6px !important;
    }
    .theme-karwei & {
      border-radius: 0 !important;
    }
  `}
`;
export {
  m as Button
};
